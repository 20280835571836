const percentage = (value: number = 0, decimals: number = 0) => {
    return Math.floor(value * 100 * Math.pow(10, decimals)) / Math.pow(10, decimals) + '%';
};

const offerCountLabel = (count: number) => {
    const countStr = count.toString();
    if (count === 1) {
        return 'dostępny kredyt';
    } else if (count > 11 && count < 15) {
        return 'dostępnych kredytów';
    } else if (
        countStr.endsWith('2') ||
        countStr.endsWith('3') ||
        countStr.endsWith('4')
    ) {
        return 'dostępne kredyty';
    } else {
        return 'dostępnych kredytów';
    }
};

const loanPeriodLabel = (period: number) => {
    if (period === 22 || period === 23 || period === 24) {
        return 'lata';
    } else {
        return 'lat';
    }
};

export const toCurrency = (value: any) => {
    if (typeof value !== 'number') {
        return value;
    }
    const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(value);
};

export const osobOrAOrY = (numberOfOsob: number): string => {
    const numberOfOsobStr: string = numberOfOsob.toString();
    if (numberOfOsob === 1) {
        return 'osoba';
    } else if (numberOfOsob > 11 && numberOfOsob < 20) {
        return 'osób';
    } else if (numberOfOsobStr.length > 2 &&
        numberOfOsobStr.endsWith('12') ||
        numberOfOsobStr.endsWith('13') ||
        numberOfOsobStr.endsWith('14')) {
        return 'osób';
    } else if (
        numberOfOsobStr.endsWith('2') ||
        numberOfOsobStr.endsWith('3') ||
        numberOfOsobStr.endsWith('4')
    ) {
        return 'osoby';
    } else {
        return 'osób';
    }
};

export const wybralaOrYOrO = (numberOfOsob: number): string => {
    const numberOfOsobStr: string = numberOfOsob.toString();
    if (numberOfOsob === 1) {
        return 'wybrała';
    }
    if (numberOfOsob > 11 && numberOfOsob < 20) {
        return 'wybrało';
    }
    if (numberOfOsobStr.length > 2 &&
        numberOfOsobStr.endsWith('12') ||
        numberOfOsobStr.endsWith('13') ||
        numberOfOsobStr.endsWith('14')) {
        return 'wybrało';
    }
    if (
        numberOfOsobStr.endsWith('2') ||
        numberOfOsobStr.endsWith('3') ||
        numberOfOsobStr.endsWith('4')
    ) {
        return 'wybrały';
    }
    return 'wybrało';
};
